import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Nav,
  Row,
  Table,
} from "reactstrap";

// core components
//import ArticleHeader from "components/Headers/ArticleHeader.js";
import { Snackbar, makeStyles } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import UserMainHeader from "../../../components/Headers/UserMainHeader";
import { AuthContext } from "../../../context/AuthContext";
import * as UserServices from "../../../services/UserServices";
import "../filestyle.css";
//import {MEDIA_URL} from "../../../constants";
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import { computeNumerotation } from "../../../constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserSummary = (props) => {
  const { register } = useForm();
  const { ref: email, ...restEmail } = register("email", { required: false });
  const { ref: username, ...restUserName } = register("username", {
    required: false,
  });
  const { ref: first_name, ...restFirstName } = register("first_name", {
    required: false,
  });
  const { ref: last_name, ...restLastName } = register("last_name", {
    required: false,
  });
  const { ref: country, ...restCountry } = register("country", {
    required: false,
  });
  const { ref: phone, ...restPhone } = register("phone", { required: false }); // const {ref: city, ...restCity} = register("city", {required: true});

  const { afterLogin } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [danger, setDanger] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openUserManagement, setOpenUserManagement] = useState(true);
  const [activeObject, setActiveObject] = useState(null);

  const [admins, setAdmins] = useState(null);
  const [openNews, setOpenNews] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);
  const [openTable, setOpenTable] = useState(true);
  const [off, setOff] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(1);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [item, setItem] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [userId, setUserId] = useState(null);
  const [requestedPage, setRequestedPage] = useState(1);
  const [openInfo, setOpenInfo] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleNews = () => {
    setOpenNews(!openNews);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const handleArticle = () => {
    setOpenArticle(!openArticle);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const handleVehicle = () => {
    setOpenVehicle(!openVehicle);
    setOpenTable(!openTable);
    setOff(!off);
  };

  const handleSearch = async (e) => {
    let nb = e.target.value;
    setItemPerPage(nb);
    let response = await UserServices.purchases(
      userId,
      undefined,
      parseInt(nb)
    );
    setPurchases(response.data);
    setCurrentPage(response.meta.current_page);
    setLastPage(response.meta.last_page);
  };

  const retrieveUsersPaginated = async (page) => {
    let response = await UserServices.purchases(userId, page, itemPerPage);
    if (response) {
      const purchases = response.data;
      if (purchases) {
        // setItem(purchases[0]?.user);
        setPurchases(purchases);
        setCurrentPage(response.meta.current_page);
        setLastPage(response.meta.last_page);
      }
    }
  };

  const handlePageClick = (data) => {
    const requestedPage = data.selected + 1;
    setRequestedPage(requestedPage);
    retrieveUsersPaginated(requestedPage);
  };

  useEffect(() => {
    const retrieveUsersPaginated = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      let userId = queryParams.get("userId");
      setUserId(userId);
      let response = await UserServices.purchases(userId);
      if (response) {
        const purchases = response.data;
        if (purchases) {
          setItem(purchases[0]?.user);
          setPurchases(purchases);
          setCurrentPage(response.meta.current_page);
          setLastPage(response.meta.last_page);
          setLoading(false);
        }
      }
    };
    retrieveUsersPaginated();
  }, []);

  const goToPurchase = (e) => {
    let purchase_id = parseInt(e.target.getAttribute("value"));

    props.history.push({
      pathname: "/admin/single-purchase",
      search: "?purchaseId=" + purchase_id,
    });
  };

  const UserDetails = () => (
    <>
      {/*<UserHeader />*/}
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {/*<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">*/}
          <Col className="order-xl-1" xl="12">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  {/*<div className="card-profile-image">*/}
                  {/*    <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                  {/*        <img*/}
                  {/*            alt="..."*/}
                  {/*            className="rounded-circle"*/}
                  {/*            // src={MEDIA_URL + user?.avatar}*/}
                  {/*        />*/}
                  {/*    </a>*/}
                  {/*</div>*/}
                </Col>
              </Row>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2>User informations</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      {openInfo ? (
                        <i
                          className="fas fa-arrow-up text-success mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenInfo(!openInfo)}
                        />
                      ) : (
                        <i
                          className="fas fa-arrow-down text-warning mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenInfo(!openInfo)}
                        />
                      )}
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <center>
                      {openInfo ? (
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <tbody>
                            <tr>
                              <td></td>
                              <td>Email</td>
                              <td> {item?.email}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Last name</span>
                              </td>
                              <td> {item?.last_name}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>First name</span>
                              </td>
                              <td> {item?.first_name}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Username</span>
                              </td>
                              <td> {item?.username}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Phone</span>
                              </td>
                              <td> {item?.phone}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Status</span>
                              </td>
                              <td> {item?.status}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Created at </span>
                              </td>
                              <td>
                                {" "}
                                {moment(item?.created_at)
                                  .utc()
                                  .format("DD  MMMM  YYYY") +
                                  " at " +
                                  moment.utc(item?.created_at).format("HH:mm")}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <span>Updated at</span>
                              </td>
                              <td>
                                {" "}
                                {moment(item?.updated_at)
                                  .utc()
                                  .format("DD  MMMM  YYYY") +
                                  " at " +
                                  moment.utc(item?.created_at).format("HH:mm")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : null}
                    </center>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

  const { user } = useContext(AuthContext);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <UserMainHeader
        handleNews={handleNews}
        handleArticle={handleArticle}
        handleVehicle={handleVehicle}
        off={off}
      />

      {isLoading ? (
        <ReactLoading type={"spin"} color="#B20101" />
      ) : (
        <>
          <UserDetails object={item} />

          <Container className="mt-4" fluid>
            <Row>
              {/*<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">*/}
              <Col className="order-xl-1" xl="12">
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      {/*<div className="card-profile-image">*/}
                      {/*    <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                      {/*        <img*/}
                      {/*            alt="..."*/}
                      {/*            className="rounded-circle"*/}
                      {/*            // src={MEDIA_URL + user?.avatar}*/}
                      {/*        />*/}
                      {/*    </a>*/}
                      {/*</div>*/}
                    </Col>
                  </Row>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Last transaction</h3>
                      </div>
                      <div className="col text-right">
                        {/*<Button*/}
                        {/*    color="primary"*/}
                        {/*    href="#pablo"*/}
                        {/*    onClick={(e) => e.preventDefault()}*/}
                        {/*    size="sm"*/}
                        {/*>*/}
                        {/*    See all*/}
                        {/*</Button>*/}
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0 pt-md-4">
                    <Row>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">transaction id</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">amount</th>
                            <th scope="col">payment method</th>
                            {/* <th scope="col">Cart articles</th> */}
                            <th scope="col">status</th>
                            <th scope="col">...</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchases?.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <span className="mb-0 text-sm">
                                  {computeNumerotation(
                                    currentPage,
                                    itemPerPage,
                                    index
                                  )}
                                </span>
                              </th>
                              <td>{item.transaction_id}</td>
                              <td>{item.quantity}</td>
                              <td>{item.amount}</td>
                              <td>{item.payment_method}</td>
                              {/*                                                             <td>
                                                                {item.purchase_carts?.map((itm, i) =>
                                                                    <div key={i}>
                                                                        <span> Name : {itm.article_name} </span>
                                                                        <span> Amount : {itm.amount}</span>
                                                                        <span> Quantity : {itm.quantity} </span>
                                                                        <br />
                                                                    </div>)}
                                                            </td> */}
                              <td>{item.status}</td>
                              <td>
                                <Tooltip title="View details" placement="top">
                                  <Button
                                    color="primary"
                                    size="sm"
                                    value={item.id}
                                    onClick={(e) => {
                                      setActiveObject({ item });
                                      goToPurchase(e);
                                    }}
                                  >
                                    View details
                                  </Button>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                  </CardBody>
                  <CardFooter className="py-4">
                    <Row>
                      <Col>
                        <Form>
                          <FormGroup
                            className="mb-0"
                            style={{ width: "100px" }}
                          >
                            <select
                              className="form-control"
                              onChange={(e) => {
                                // console.log(e.target.value);
                                handleSearch(e);
                              }}
                            >
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="25" selected>
                                25
                              </option>
                            </select>
                          </FormGroup>
                        </Form>
                      </Col>
                      <Col>
                        <nav aria-label="...">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={lastPage}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={6}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end mb-0"
                            }
                            pageClassName={"page-item"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default UserSummary;
