import React, {useContext, useEffect, useState} from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import {
    Button, Card, CardHeader, Table, Container, Row, Col,
} from "reactstrap";

// core components
import {
    chartOptions, parseOptions,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import {AuthContext} from "../context/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@material-ui/lab";
import {overview, updateAdminInfos} from "../services/AdminServices";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {computeNumerotation} from "../constants";

const Index = (props) => {
    const {user} = useContext(AuthContext);
    const {afterLogin} = useContext(AuthContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [openBody, setOpenBody] = useState(true);
    const [response, setResponse] = useState(null);
    const [articles, setArticles] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const completeInfos = async (data) => {

        try {
            await updateAdminInfos(user?.id, data);

            setAlertMessage("Update sucessfully");
            setAlertType("success");
            handleOpenAlert();
            setOpenBody(!openBody);
            afterLogin();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    useEffect(() => {
        const retrievePurchases = async () => {
            let data = await overview();
            setResponse(data);
            let arr = Object.values(data.recent_articles);
            let arr2 = Object.values(data.recent_purchases);
            setArticles(arr);
            setPurchases(arr2);
        }

        retrievePurchases();
    }, []);

    return (<>
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={6000}
                  onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Header nb_users={response?.nb_users} sales={response?.sales} total_purchase={response?.total_purchase}
                total_article={response?.total_article}/>

        {openBody ? <Container className="mt--7" fluid>
            {/*<Row>*/}
            {/*  <Col className="mb-5 mb-xl-0" xl="8">*/}
            {/*    <Card className="bg-gradient-default shadow">*/}
            {/*      <CardHeader className="bg-transparent">*/}
            {/*        <Row className="align-items-center">*/}
            {/*          <div className="col">*/}
            {/*            <h6 className="text-uppercase text-light ls-1 mb-1">*/}
            {/*              Overview*/}
            {/*            </h6>*/}
            {/*            <h2 className="text-white mb-0">Sales value</h2>*/}
            {/*          </div>*/}
            {/*          <div className="col">*/}
            {/*            <Nav className="justify-content-end" pills>*/}
            {/*              <NavItem>*/}
            {/*                <NavLink*/}
            {/*                    className={classnames("py-2 px-3", {*/}
            {/*                      active: activeNav === 1,*/}
            {/*                    })}*/}
            {/*                    href="#pablo"*/}
            {/*                    onClick={(e) => toggleNavs(e, 1)}*/}
            {/*                >*/}
            {/*                  <span className="d-none d-md-block">Month</span>*/}
            {/*                  <span className="d-md-none">M</span>*/}
            {/*                </NavLink>*/}
            {/*              </NavItem>*/}
            {/*              <NavItem>*/}
            {/*                <NavLink*/}
            {/*                    className={classnames("py-2 px-3", {*/}
            {/*                      active: activeNav === 2,*/}
            {/*                    })}*/}
            {/*                    data-toggle="tab"*/}
            {/*                    href="#pablo"*/}
            {/*                    onClick={(e) => toggleNavs(e, 2)}*/}
            {/*                >*/}
            {/*                  <span className="d-none d-md-block">Week</span>*/}
            {/*                  <span className="d-md-none">W</span>*/}
            {/*                </NavLink>*/}
            {/*              </NavItem>*/}
            {/*            </Nav>*/}
            {/*          </div>*/}
            {/*        </Row>*/}
            {/*      </CardHeader>*/}
            {/*      <CardBody>*/}
            {/*         Chart */}
            {/*        <div className="chart">*/}
            {/*          <Line*/}
            {/*              data={chartExample1[chartExample1Data]}*/}
            {/*              options={chartExample1.options}*/}
            {/*              getDatasetAtEvent={(e) => console.log(e)}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </CardBody>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*  <Col xl="4">*/}
            {/*    <Card className="shadow">*/}
            {/*      <CardHeader className="border-0">*/}
            {/*        <Row className="align-items-center">*/}
            {/*          <div className="col">*/}
            {/*            <h3 className="mb-0">Social traffic</h3>*/}
            {/*          </div>*/}
            {/*          <div className="col text-right">*/}
            {/*            <Button*/}
            {/*                color="primary"*/}
            {/*                href="#pablo"*/}
            {/*                onClick={(e) => e.preventDefault()}*/}
            {/*                size="sm"*/}
            {/*            >*/}
            {/*              See all*/}
            {/*            </Button>*/}
            {/*          </div>*/}
            {/*        </Row>*/}
            {/*      </CardHeader>*/}
            {/*      <Table className="align-items-center table-flush" responsive>*/}
            {/*        <thead className="thead-light">*/}
            {/*        <tr>*/}
            {/*          <th scope="col">Transaction ID</th>*/}
            {/*          <th scope="col">Products</th>*/}
            {/*          <th scope="col">Price</th>*/}
            {/*          <th scope="col"/>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*        <tr>*/}
            {/*          <th scope="row">twitter</th>*/}
            {/*          <td>2,645</td>*/}
            {/*          <td>*/}
            {/*            <div className="d-flex align-items-center">*/}
            {/*              <span className="mr-2">30%</span>*/}
            {/*              <div>*/}
            {/*                <Progress*/}
            {/*                    max="100"*/}
            {/*                    value="30"*/}
            {/*                    barClassName="bg-gradient-warning"*/}
            {/*                />*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </td>*/}
            {/*        </tr>*/}
            {/*        </tbody>*/}
            {/*      </Table>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="8">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Recently added products</h3>
                                </div>
                                <div className="col text-right">
                                    <Link to={{
                                        pathname: '/admin/manage-articles'
                                    }}>
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            size="sm"
                                        >
                                            See all
                                        </Button>
                                    </Link>
                                </div>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Association</th>
                                <th scope="col">name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Created at</th>
                            </tr>
                            </thead>
                            <tbody>
                            {articles?.map((item, index) =>
                                <tr key={item.id}>
                                    <th scope="row">
                                    <span className="mb-0 text-sm">
                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                    </span>
                                    </th>
                                <td>{item.association}</td>
                                <td>{item.name}</td>
                                <td>${item.price}</td>
                                <td>
                                    {moment(item.update_at).format("YYYY/MM/DD kk:mm:ss")}
                                </td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col xl="4">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Recent purchases</h3>
                                </div>
                                <div className="col text-right">
                                    <Link to={{
                                        pathname: '/admin/all-purchases'
                                    }}>
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            size="sm"
                                        >
                                            See all
                                        </Button>
                                    </Link>
                                </div>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Buyer email</th>
                                <th scope="col">Payment method</th>
                                <th scope="col">Amount</th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            {purchases?.map((item, index) => <tr>
                                <th scope="row">
                                    <span className="mb-0 text-sm">
                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                    </span>
                                </th>
                                <td>{item.transaction_id}</td>
                                <td>{item.email}</td>
                                <td>{item.payment_method}</td>
                                <td>${item.amount}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </Container> : null}
        {/*{openFirstConnectionModal ? <Modal object={activeObject} /> : null}*/}

    </>);
};

export default Index;
