import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
// core components
import React, { useState } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@mui/material/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Snackbar from "@mui/material/Snackbar";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const modules = {
  toolbar: [
    // Header options: choose between different header levels or no header
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // Font style options: bold, italic, underline, strike
    ["bold", "italic", "underline", "strike"],

    // Script/subscript/superscript: allows superscript or subscript text
    [{ script: "sub" }, { script: "super" }],

    // List options: ordered list or bullet list
    [{ list: "ordered" }, { list: "bullet" }],

    // Blockquote and code-block
    ["blockquote", "code-block"],

    // Text alignment options: left, center, right, justify
    [{ align: [] }],

    // Link, image, and video insertion
    // ["link", "image", "video"],
    ["link"],

    // Text color and background color

    [{ color: [] }, { background: [] }],

    // Font size options
    [{ size: ["small", false, "large", "huge"] }],

    // Dropdown for font family selection
    [{ font: [] }],

    // Indent and outdent options
    [{ indent: "-1" }, { indent: "+1" }],

    // Direction of the text
    [{ direction: "rtl" }],

    // Clean: removes formatting from the selected text
    ["clean"],
  ],
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const JobForm = (props) => {

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { ref: title, ...restTitle } = register("title", {
    required: true,
    minLength: 5,
  });
  const { ref: domain, ...restDomain } = register("domain", {
    required: true,
    minLength: 5,
  });

  const { ref: contact_first_name, ...restFirstName } = register("contact_first_name", {
    required: true,
    minLength: 5,
  });
  const { ref: contact_last_name, ...restLastName } = register("contact_last_name", {
    required: true,
    minLength: 5,
  });

  const { ref: contact_email, ...restEmail } = register("contact_email", {
    required: true,
    minLength: 5,
  });
  const { ref: contact_phone, ...restPhone} = register("contact_phone", {
    required: true,
    minLength: 5,
  });
  const { ref: salary_start, ...restSalaryStart } = register("salary_start");
  const { ref: salary_end, ...restSalaryEnd } = register("salary_end");
  const { ref: start_date, ...restStartDate } = register("start_date");
  const { ref: end_date, ...restEndDate } = register("end_date");
  const { ref: type, ...restType } = register("type");

  const [qualification, setQualification] = useState("");
  const [benefit, setBenefit] = useState("");
  const [expectation, setExpectation] = useState("");
  //const [qualifications, setQualifications] = useState([]);
  //const [benefits, setBenefits] = useState([]);
  //const [expectations, setExpectations] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isCDD, setIsCDD] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeType = (e) => {
    if(e.target.value == "2"){
      setIsCDD(true)
    }else{
      setIsCDD(false)
    }
    props.handleChangeInput(e)
  };

  const handleChangeQualification = (e) => {
    setQualification(e.target.value);
  };

  const handleChangeBenefit = (e) => {
    setBenefit(e.target.value);
  };

  const handleChangeExpectation = (e) => {
    setExpectation(e.target.value);
  };

  const addQualification = () => {
    if (
      typeof qualification === "string" &&
      qualification !== null &&
      qualification !== undefined &&
      qualification !== ""
    ) {
      let key = uuidv4();
      props.setQualificationData((prevQualifications) => [...prevQualifications, {key: key, element: qualification }]);
      setQualification("");
    }else{
      setAlertMessage("Qualification must be a valid string");
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const addBenefit = () => {
    if (
      typeof benefit === "string" &&
      benefit !== null &&
      benefit !== undefined&&
      benefit !== ""
    ) {
      let key = uuidv4();
      props.setBenefitData((prevBenefits) => [...prevBenefits, {key: key, element: benefit }]);
      setBenefit("");
    }else{
      setAlertMessage("Benefit must be a valid string");
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const addExpectation = () => {
    if (
      typeof expectation === "string" &&
      expectation !== null &&
      expectation !== undefined &&
      expectation !== ""
    ) {
      let key = uuidv4();
      props.setExpectationData((prevExpectations) =>[...prevExpectations, {key: key, element: expectation }]);
      setExpectation("");
    }else{
      setAlertMessage("Expectation must be a valid string");
      setAlertType("error");
      handleOpenAlert();
    }
  };

  function isNotValidString(element) {
    let result = true;
    if (
      typeof element !== "string" ||
      element === null ||
      element === undefined
    ) {
      result = true;
    } else
      result =
        element.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
        !element.includes("<img");
    return result;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>


                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="title"
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Title here ..."
                          value={props.currentJob.title}
                          type="text"
                          {...restTitle}
                          innerRef={title}
                          onChange={props.handleChangeInput}
                        />
                        {errors.title && (
                          <FormHelperText style={{ color: "red" }}>
                            At least 5 characters are required !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Domain <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="domain"
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Domain here ..."
                          value={props.currentJob.domain}
                          type="text"
                          {...restDomain}
                          innerRef={domain}
                          onChange={props.handleChangeInput}
                        />
                        {errors.domain && (
                          <FormHelperText style={{ color: "red" }}>
                            At least 5 characters are required !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup error={errors.type ? true : undefined}>
                        <label className="form-control-label">
                          Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={props.currentJob.type}
                          type="select"
                          {...restType}
                          innerRef={type}
                          aria-invalid={errors.type ? "true" : "false"}
                          onChange={handleChangeType}
                        >
                          <option value="">
                            ------Select Type------
                          </option>
                          <option value="1">CDI</option>
                          <option value="2">CDD</option>
                        </Input>
                        {/* {errors.type && (
                          <FormHelperText style={{ color: "red" }}>
                            Select one type
                          </FormHelperText>
                        )} */}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Min Salary{" "}
                          
                        </label>
                        <Input
                          name="salary_start"
                          className="form-control-alternative"
                          id="input-first-name"
                          placeholder="10"
                          value={props.currentJob.salary_start}
                          type="number"
                          {...restSalaryStart}
                          innerRef={salary_start}
                          onChange={props.handleChangeInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Max Salary 
                        </label>
                        <Input
                          name="salary_end"
                          className="form-control-alternative"
                          placeholder="20"
                          value={props.currentJob.salary_end}
                          type="number"
                          {...restSalaryEnd}
                          innerRef={salary_end}
                          onChange={props.handleChangeInput}
                        />
                      </FormGroup>
                    </Col>
                    {isCDD &&
                    <>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Start Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="start_date"
                          className="form-control-alternative"
                          id="input-last-name"
                          value={props.currentJob.start_date}
                          type="date"
                          {...restStartDate}
                          innerRef={start_date}
                          onChange={props.handleChangeInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          End Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="end_date"
                          className="form-control-alternative"
                          id="input-last-name"
                          value={props.currentJob.end_date}
                          type="date"
                          {...restEndDate}
                          innerRef={end_date}
                          onChange={props.handleChangeInput}
                        />
                      </FormGroup>
                    </Col>
                    </>}
                  </Row>
                </div>

                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>
                          Description <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <ReactQuill
                          modules={modules}
                          theme="snow"
                          value={props.formattedDescription}
                          onChange={(e) => props.setFormattedDescription(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <h3 >Contact Information for this Job</h3>
                <hr className="my-1" />
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          First Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="contact_first_name"
                          className="form-control-alternative"
                          placeholder=""
                          value={props.currentJob.contact_first_name}
                          type="text"
                          {...restFirstName}
                          innerRef={contact_first_name}
                          onChange={props.handleChangeInput}
                        />
                        {errors.contact_first_name && (
                          <FormHelperText style={{ color: "red" }}>
                            First name must be valid !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          Last Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="contact_last_name"
                          className="form-control-alternative"
                          placeholder="20"
                          value={props.currentJob.contact_last_name}
                          type="text"
                          {...restLastName}
                          innerRef={contact_last_name}
                          onChange={props.handleChangeInput}
                        />
                        {errors.contact_last_name && (
                          <FormHelperText style={{ color: "red" }}>
                            Last name must be valid !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="contact_email"
                          className="form-control-alternative"
                          id="input-last-name"
                          placeholder="20"
                          value={props.currentJob.contact_email}
                          type="email"
                          {...restEmail}
                          innerRef={contact_email}
                          onChange={props.handleChangeInput}
                        />
                        {errors.contact_email && (
                          <FormHelperText style={{ color: "red" }}>
                            Email must be valid !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          name="contact_phone"
                          className="form-control-alternative"
                          id="input-last-name"
                          placeholder="20"
                          value={props.currentJob.contact_phone}
                          type="text"
                          {...restPhone}
                          innerRef={contact_phone}
                          onChange={props.handleChangeInput}
                        />
                        {errors.contact_phone && (
                          <FormHelperText style={{ color: "red" }}>
                            Phone must be positive !
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="10">
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          Expectations
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="textarea"
                          className="form-control-alternative"
                          placeholder="Expectation here ..."
                          rows={2}
                          value={expectation}
                          onChange={handleChangeExpectation}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Button
                        className="mt-5"
                        color="info"
                        type="button"
                        size="sm"
                        onClick={addExpectation}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="10">
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          Benefits
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="textarea"
                          className="form-control-alternative"
                          placeholder="Benefits here ..."
                          rows={2}
                          value={benefit}
                          onChange={handleChangeBenefit}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Button
                        className="mt-5"
                        color="info"
                        type="button"
                        size="sm"
                        onClick={addBenefit}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="10">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Qualifications
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="textarea"
                          className="form-control-alternative"
                          placeholder="Qualifications here ..."
                          rows={2}
                          value={qualification}
                          onChange={handleChangeQualification}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Button
                        className="mt-5"
                        color="info"
                        type="button"
                        size="sm"
                        onClick={addQualification}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </div>
                <br />



          

    </>
  );
};

export default JobForm;
